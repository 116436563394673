@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --fc-border-color: #1f2937;
  --fc-button-bg-color: #ffffff;
  --fc-button-hover-bg-color: #bcc7d5;
  --fc-button-text-color: #000000;
}

.loading-indicator:before {
    content: '';
    background: #00000080;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .loading-indicator:after {
    content: ' ';
    position: fixed;
    top: 40%;
    left: 45%;
    z-index: 10010;
    color:white;
    text-align:center;
    font-weight:bold;
    font-size:1.2rem;        
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #0474bf; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.underline-gradient {
  background-image: linear-gradient(to right, rgba(127, 0, 255, 0.5), rgba(0, 181, 226, 0.5));
  background-repeat: no-repeat;
  background-size: 120% 0.2em; /* Increase the width of the underline */
  background-position: 30% 88%; 
  transition: background-size 0.3s;
  border-radius: 0.3em;
  
}